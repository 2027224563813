<template>
  <nav
    class="navigation"
    :class="[toggleSearchQuery ? 'nav-searchOpen' : { 'nav-untransparent': !showNavbar }]"
    @mouseover="mouseOver()"
    @mouseleave="mouseLeave()"
  >
    <div class="container w-full flex">
      <a href="/">
        <Icon
          v-if="henleySite"
          icon="henley-logo-black"
          class="logo"
          :class="[toggleSearchQuery ? '' : { 'main-logo': showNavbar }]"
        />
        <Icon
          v-else
          icon="ICMA-Centre-Black"
          class="logoICMA"
          :class="[toggleSearchQuery ? '' : { 'main-logo': showNavbar }]"
        />
      </a>
      <a href="/">
        <Icon
          v-if="henleySite"
          icon="henley-logo-white"
          class="logo"
          :class="[
            toggleSearchQuery ? 'hide-alternativeLogo' : { 'hide-alternativeLogo': !showNavbar },
          ]"
          @mouseover="showNavbar = false"
          @mouseleave="showNavbar = true"
        />
        <Icon
          v-else
          icon="ICMA-Centre-White"
          class="logoICMA"
          :class="[toggleSearchQuery ? '' : { 'main-logo': !showNavbar }]"
        />
      </a>
      <masthead-navigation :items="items" :showNavbar="showNavbar" class="desktopPaneNav" />
      <section class="rightNavigation">
        <div class="innerRightNav">
          <span v-if="!isSearchPage" class="search" @click="showSearch">
            <Icon
              v-if="toggleSearchQuery || scrolledNavbar"
              icon="search-icon-blue"
              class="magnify"
            />
            <Icon v-else icon="search-icon-white" class="magnify" />
          </span>
          <img
            class="lineSeperator"
            src="@/assets/icons/masthead/line.png"
            alt="navigation line seperator"
          />
          <div
            class="login"
            @mouseover="(showMenu = true), (arrow = 'up')"
            @mouseleave="(showMenu = false), (arrow = 'down')"
          >
            <a href="#">Login</a>
            <Icon v-if="toggleSearchQuery || scrolledNavbar" icon="login-icon-blue" />
            <Icon v-else icon="login-icon-white" />
            <span>
              <Icon
                v-if="toggleSearchQuery || !showNavbar"
                :class="arrow"
                icon="dropdown-icon-blue"
                class="loginArrow"
              />
              <Icon v-else :class="arrow" icon="dropdown-icon-white" class="loginArrow" />
            </span>
            <section v-if="showMenu" class="dropDown">
              <a href="https://www.henley.ac.uk/student-login" target="_blank"
                >Student Login <InlineArrow name="default-arrow"
              /></a>
              <HorizontalLine />
              <a href="https://live.henley.ac.uk" target="_blank"
                >Alumni Login <InlineArrow name="default-arrow"
              /></a>
              <HorizontalLine />
              <a href="https://www.henley.ac.uk/buzz" target="_blank"
                >Staff Login <InlineArrow name="default-arrow"
              /></a>
            </section>
            <div :class="{ 'activeBarStatus ': showMenu }"></div>
          </div>
          <div class="buttonContainer" v-for="item in items" :key="item.id">
            <a v-if="item.cta" :href="item.link">
              <button v-if="item.cta">{{ item.text }}</button>
            </a>
          </div>
          <a href="https://www.henleybusinessschool.cn/" target="_blank" class="switchlanguage"
            >中文</a
          >
        </div>
      </section>
    </div>
  </nav>
</template>
<style scoped>
button {
  top: 18px;
  right: 74px;
  display: flex;
  width: 135px;
  height: 44px;
  flex-direction: row;
  align-items: flex-start;
  padding: 12px 20px;
  background: #ffcd00;
  border-radius: 5px;
  color: #0a3b85;
}

button:hover {
  background: #ffba23;
}

.buttonContainer {
  align-self: center;
}

.lineSeperator {
  height: 30px;
  margin: auto;
}

.navigation {
  position: fixed;
  z-index: 28;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  width: 100%;
  width: 100vw;
  height: 80px;
  max-height: 80px;
  align-items: center;
  padding: 15px 70px;
  background: transparent;
  color: white;
  font-size: 12px;
  font-weight: 500;
}

.navigation > .container {
  align-items: center;
}

.nav-untransparent {
  background: white;
  color: #0a3b85;
}

.nav-searchOpen {
  z-index: 29;
  background: #f8f9fb;
  color: #0a3b85;
}

.nav-untransparent a,
.nav-searchOpen a {
  color: #0a3b85;
}

.dropDown {
  position: absolute;
  z-index: -1;
  top: 84px;
  left: 0;
  width: 222px;
  padding: 0;
  border-top: 1px solid #ededed;
  background: #fff;
  transition: transform 0.4s cubic-bezier(0.45, 0, 0.55, 1);
}

.dropDown a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px !important;
  color: #000;
}

.dropDown a:hover {
  background: #f2f5f9;
}

.dropDown svg {
  margin: auto;
}

.dropDown a svg {
  width: 12px;
  margin-right: 0;
}

.desktopPaneNav {
  margin-right: 0;
  margin-left: auto;
  float: right;

  /* position: absolute;
  right: 410px; */
}

.rightNavigation {
  display: flex;
  height: 88px;
  flex-direction: row;
  align-self: center;
  justify-content: center;
  margin-right: 0;
  margin-left: 0;
  float: right;

  /* position: absolute;
  right: 60px; */
}

.logo {
  width: 96px;
}

.logoICMA {
  width: 220px;
}

.main-logo {
  display: none;
}

.alternativeLogo {
  position: absolute;
  top: -7.5px;
  left: 25px;
}

.hide-alternativeLogo {
  display: none;
}

.desktopNav {
  display: flex;
  flex-direction: row;
}

.innerRightNav {
  display: flex;
  min-width: max-content;
}

.search {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 20px;
  cursor: pointer;
}

/* stylelint-disable no-descending-specificity */
.search svg {
  width: 36px;
}
/* stylelint-enable no-descending-specificity */

.login {
  position: relative;

  /* z-index: 99; */
  display: flex;
  align-items: center;
  margin: auto 20px;
}

/* stylelint-disable no-descending-specificity */
.login a {
  /* display: flex; */

  /* align-items: center; */
  padding: 35px 25px;
  padding-right: 10px;
  padding-left: 0;
  text-decoration: none;
}
/* stylelint-enable no-descending-specificity */

.login span {
  margin-left: 6px;
}

.loginArrow {
  width: 12px;
}

.switchlanguage {
  margin: auto 0 auto 20px;
  text-decoration: none;
}

.activeBarStatus {
  position: absolute;
  right: 0;
  bottom: 4px;
  left: 0;
  border-top: 2px solid #0a3b85;
}

.up {
  transform: rotate(180deg);
  transition: transform 450ms ease-in-out;
}

.down {
  transition: transform 450ms ease-in-out;
}

.login-icon-white,
.login-icon-blue {
  width: 12px;
}

.magnify:hover {
  background: rgba(10, 59, 133, 0.1);
  border-radius: 50%;
}

@media (max-width: 1340px) {
  .navigation {
    padding: 15px 20px;
  }

  .rightNavigation {
    right: 20px;
  }

  .desktopPaneNav {
    right: 370px;
  }
}
</style>
<script>
import { mapGetters, mapState, mapActions } from "vuex";

import MastheadNavigation from "@/components/assemblies/masthead/components/MastheadNavigation";
import HorizontalLine from "@/components/assemblies/masthead/components/elements/HorizontalLine";
import InlineArrow from "@/components/assemblies/masthead/components/elements/InlineArrow";

import Icon from "@/components/atoms/icon/Icon";

export default {
  components: {
    MastheadNavigation,
    HorizontalLine,
    InlineArrow,
    Icon,
  },
  data() {
    return {
      currentUrl: window.location.pathname,
      activeColour: this.currentUrl == "/" ? "#0a3b85" : "#fffff",
      showNavbar: this.currentUrl === "/" ? true : false,
      showMenu: false,
      scrolledNavbar: this.currentUrl === "/" ? false : true,
      activeNavbar: this.currentUrl === "/" ? true : false,
      lastScrollPosition: 0,
      arrow: "down",
    };
  },
  computed: {
    ...mapState("masthead", ["toggleSearchQuery"]),
    ...mapState("search", ["isSearchPage"]),
    ...mapState("ui", ["site"]),
    ...mapGetters("ui", ["is2lg"]),
    ...mapState("masthead", ["items"]),
    henleySite() {
      return this.site.handle === "henleyUk";
    },
  },
  methods: {
    ...mapActions("masthead", ["toggleSearch"]),
    showSearch() {
      this.toggleSearch();
      this.$store.commit("search/SET_QUERY", "");
      this.$store.commit("search/SET_QUERY_FOCUS", true);
    },
    onScroll() {
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollPosition < 0) {
        return;
      }
      if (this.currentUrl !== "/") {
        this.showNavbar = false;
        this.scrolledNavbar = true;
      } else {
        this.showNavbar = currentScrollPosition === 0;
        this.scrolledNavbar = currentScrollPosition > 0;
      }
      this.lastScrollPosition = currentScrollPosition;
    },
    mouseOver() {
      this.showNavbar = false;
      this.activeColour = "#0a3b85";
      this.activeNavbar = false;
      this.scrolledNavbar = true;
    },
    mouseLeave() {
      if (this.toggleSearchQuery) {
        this.showNavbar = false;
        this.activeColour = "#0a3b85";
        this.activeNavbar = false;
        this.scrolledNavbar = true;
      } else {
        if (
          window.pageYOffset ||
          document.documentElement.scrollTop !== 0 ||
          this.currentUrl !== "/"
        ) {
          setTimeout(() => {
            this.showNavbar = false;
            this.activeColour = "#ffffff";
            this.activeNavbar = true;
            this.scrolledNavbar = true;
          }, 700);
        } else {
          setTimeout(() => {
            this.showNavbar = true;
            this.activeColour = "#ffffff";
            this.activeNavbar = true;
            this.scrolledNavbar = false;
          }, 700);
        }
      }
    },
  },
  mounted() {
    if (this.toggleSearchQuery) {
      this.showNavbar = false;
      this.activeColour = "#0a3b85";
      this.activeNavbar = false;
      this.scrolledNavbar = true;
    } else {
      this.currentUrl = window.location.pathname;
      this.scrolledNavbar =
        this.currentUrl === "/"
          ? false
          : this.currentUrl === "/" && this.toggleSearchQuery === true
          ? true
          : true;
      this.showNavbar =
        this.currentUrl === "/"
          ? true
          : this.currentUrl === "/" && this.toggleSearchQuery === true
          ? false
          : false;
    }
    window.addEventListener("scroll", this.onScroll);
  },
  beforeUnmount() {
    this.currentUrl = window.location.pathname;
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>

import Vue from "vue";
// import svg4everybody from "svg4everybody";

import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";

import "@/assets/styles/app.postcss";
import "@/lib/svg-sprite";
import initModules from "@/lib/init-modules";
import initStateForSearchPage from "@/store/helpers/initStateForSearchPage";

import modulesEnabled from "@/modulesEnabled";

Vue.config.productionTip = false;

initStateForSearchPage();

const setJsDocumentClasses = () => {
  const docEl = document.documentElement;

  docEl.classList.remove("js-loading");
  docEl.classList.add("js-loaded");
};

function initSentry({ ENABLED = false, DSN = null, DEBUG = false, RELEASE = "" }) {
  if (!ENABLED) {
    return;
  }
  if (!DSN) {
    console.error("Sentry integration requires a DSN");
  } else {
    Sentry.init({
      dsn: DSN,
      release: RELEASE,
      ignoreErrors: [
        // Random plugins/extensions
        "top.GLOBALS",
        "fb_xd_fragment",
      ],
      blacklistUrls: [
        "^chrome-extension:",
        "/storage.googleapis.com/snapengage-eu/i",
        "/siteimproveanalytics.com/i",
        "^moz-extension:",
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
      ],
      integrations: [
        new Integrations.Vue({
          Vue,
          attachProps: true,
          logErrors: DEBUG,
        }),
      ],
    });
  }
}

/* eslint-disable no-param-reassign */
function init(APP) {
  APP.DEBUG = typeof APP.DEBUG !== "undefined" ? APP.DEBUG : !process.env.NODE_ENV === "production";

  const { DEBUG } = APP;

  if (DEBUG) {
    APP.addModule("breakpointDebug");
  }

  if (APP.CONFIG.SENTRY) {
    APP.sentry = initSentry({ ...APP.CONFIG.SENTRY, DEBUG });
  }

  APP.modules = initModules(APP.modules, modulesEnabled, DEBUG);
}

window.addEventListener("DOMContentLoaded", () => {
  setJsDocumentClasses();
  // svg4everybody();
  init(window.APP);
});
